<template>
  <b-card>
    <FormView
      :data="$store.getters['review/detailData']"
      :fields="fields"
      @load="loadData"
    />
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormView,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'createdAt',
          label: 'Date',
          type: 'datetime',
        },
        // TODO: order number
        // TODO: creator
        // TODO: creator email
        {
          key: 'fullName',
          label: 'Supporter',
          type: 'profile',
          image: 'avatar',
          sortable: false,
        },
        {
          key: 'service.name',
          label: 'Listing',
          type: 'profile',
          image: 'service.media[0].thumbUrl',
          url: {
            callback(data) {
              return `/service/${data.service.id}`
            },
          },
        },
        {
          key: 'review',
          label: 'Review',
        },
        {
          key: 'stars',
          label: 'Rating',
          type: 'rating',
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('review/getDetail', id)
    },
  },
}
</script>

  <style scoped>

  </style>
